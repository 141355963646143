<template>
  <div style="height:100%">
    <fw-title :open="open" :checks="true" titles="商品明细" @changes="open = !open" />
    <div v-show="open" style="height:100%">
      <vxe-table
        style="min-height:10vh"
        class="mytable-scrollbar"
        stripe
        highlight-hover-row
        size="small"
        id="toolbar_demo3"
        ref="xTable1"
        :data="tableList"
      >
        <vxe-column
          type="seq"
          title="序号"
          width="50"
          align="center"
          field="xuhao"
          fixed="left"
        ></vxe-column>

        <vxe-column min-width="130" align="center" fixed="left">
          <template #header>
            <p>图片</p>
          </template>
          <template #default="{ row }">
            <el-image
              style="width: 100px; height: 100px"
              :src="row.com_sku.cover_img_path"
              fit="cover"
              :preview-src-list="[row.com_sku.cover_img_path]"
            ></el-image>
          </template>
        </vxe-column>
        <vxe-column min-width="160" fixed="left">
          <template #header>
            <p>商品信息</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.com_sku.name">
              {{ row.com_sku.name || "--" }}
            </p>
            <p
              class="ellipsisText"
              :title="
                `${row.com_sku.serial_number}${
                  row.com_sku.serial_number ? '-' + row.com_sku.goods_small_sort_num : ''
                }`
              "
            >
              {{
                `${row.com_sku.serial_number}${
                  row.com_sku.serial_number ? "-" + row.com_sku.goods_small_sort_num : ""
                }`
              }}
            </p>
            <p class="ellipsisText" :title="row.com_sku.info">
              {{ row.com_sku.info || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>商品规格</p>
          </template>
          <template #default="{ row }">
            <p class="duohangEllipsisText" :title="specJson(row.com_sku.spec_json)">
              {{ specJson(row.com_sku.spec_json) || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="80">
          <template #header>
            <p>单位</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.com_sku.unit_name">
              {{ row.com_sku.unit_name || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>平台SPU</p>
            <p>平台SKU</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.com_sku.sku_number">
              {{ row.com_sku.sku_number || "--" }}
            </p>
            <p class="ellipsisText" :title="row.com_sku.spu_number">
              {{ row.com_sku.spu_number || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>销售单价</p>
          </template>
          <template #default="{ row }">
            <template v-if="row.protocol_sku_price != '0.00'">
              <p class="ellipsisText" :title="row.protocol_sku_price">
                {{ row.protocol_sku_price | formatMoney }}
              </p>
            </template>
            <template v-else>
              <p class="ellipsisText" :title="row.com_sku_price">
                {{ row.com_sku_price | formatMoney }}
              </p>
            </template>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>订购数量</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.buy_count">
              {{ row.buy_count || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>金额小计</p>
          </template>
          <template #default="{ row }">
            <p style="color: #f74848">{{ row.com_sku_subtotal | formatMoney }}</p>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <fw-chosegoods ref="fwchosegoods" />
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    tableList: {
      type: Array,
      default: [],
    },
  },
  created() {},
  mounted() {},
  data() {
    return {
      open: true,
    };
  },
  inject: ["getBasicInfo"],
  watch: {},
  computed: {
    specJson() {
      return list => {
        if (!list || !list.length) return "--";
        return (
          list
            ?.map(item => {
              let msg = item.spec_name + "  " + item.spec_value_name + item.spec_unit_name;
              return msg;
            })
            ?.join("、") ?? "--"
        );
      };
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped></style>
