var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin":"10px 0"}},[_c('el-row',{staticStyle:{"align-items":"center"},attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":2}},[_c('fw-title',{staticStyle:{"padding-top":"20px"},attrs:{"open":_vm.open,"checks":true,"titles":"发货信息"},on:{"changes":function($event){_vm.open = !_vm.open}}})],1),_c('el-col',{attrs:{"span":22}},[(_vm.tableList.length > 0)?_c('div',[_c('span',{staticStyle:{"color":"#666"}},[_vm._v("合计发货金额 ：")]),_c('span',{staticStyle:{"color":"#f95c48","font-weight":"bold","font-size":"16px"}},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(_vm.totalPrice))+" ")])]):_c('div',[_vm._v(" ")])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}]},[_c('vxe-table',{ref:"xTable1",staticClass:"mytable-scrollbar",staticStyle:{"min-height":"10vh"},attrs:{"stripe":"","highlight-hover-row":"","size":"small","id":"toolbar_demo3","row-config":{ height: 120 },"data":_vm.tableList}},[_c('vxe-column',{attrs:{"fixed":"left","type":"seq","title":"序号","width":"50","align":"center","field":"xuhao"}}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("发货单号")]),_c('p',[_vm._v("发货方名称")]),_c('p',[_vm._v("收货状态")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText ",attrs:{"title":((row.serial_number || '--') + " ")}},[_vm._v(" "+_vm._s(((row.serial_number || "--") + " "))+" ")]),_c('p',{staticClass:"ellipsisText ",attrs:{"title":((row.send_name || '--') + " ")}},[_vm._v(" "+_vm._s(((row.send_name || "--") + " "))+" ")]),(row.receipt_status == 1)?[_c('span',{staticClass:"colors",staticStyle:{"background-color":"#f8ba43"}}),_c('span',{staticStyle:{"color":"#f8ba43"}},[_vm._v("待收货")])]:(row.receipt_status == 2)?[_c('span',{staticClass:"colors",staticStyle:{"background-color":"#7ac756"}}),_c('span',{staticStyle:{"color":"#7ac756"}},[_vm._v("已收货")])]:[_vm._v(" -- ")]]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("发货金额")]),_c('p',[_vm._v("收货金额")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",staticStyle:{"color":"#f95c48","font-weight":"bold"},attrs:{"title":row.shipping_amount}},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(row.shipping_amount))+" ")]),_c('p',{staticClass:"ellipsisText",staticStyle:{"color":"#f95c48","font-weight":"bold"},attrs:{"title":row.receive_amount}},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(row.receive_amount))+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("收货人")]),_c('p',[_vm._v("联系方式")]),_c('p',[_vm._v("收货地址")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.receive_by}},[_vm._v(" "+_vm._s(row.receive_by || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.receive_mobile}},[_vm._v(" "+_vm._s(row.receive_mobile || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.receive_address}},[_vm._v(" "+_vm._s(row.receive_address || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("物流单号")]),_c('p',[_vm._v("物流到货时间")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.logistics_serial_number}},[_vm._v(" "+_vm._s(row.logistics_serial_number || "--")+" ")]),(row.arrival_at == '0000-00-00 00:00:00')?[_vm._v(" -- ")]:[_c('p',{staticClass:"ellipsisText",attrs:{"title":row.arrival_at}},[_vm._v(" "+_vm._s(row.arrival_at || "--")+" ")])]]}}])}),_c('vxe-column',{attrs:{"min-width":"230"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("发货提交人 | 提交时间")]),_c('p',[_vm._v("收货提交人 | 提交时间")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText ",attrs:{"title":((row.send_by || '--') + " | " + (row.send_at || '--'))}},[_vm._v(" "+_vm._s(((row.send_by || "--") + " | " + (row.send_at || "--")))+" ")]),_c('p',{staticClass:"ellipsisText ",attrs:{"title":((row.receive_by || '--') + " | " + (row.receive_at || '--'))}},[_vm._v(" "+_vm._s(((row.receive_by || "--") + " | " + (row.receive_at || "--")))+" ")])]}}])}),_c('vxe-column',{attrs:{"title":"操作","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row._oper && row._oper.length > 0)?_vm._l((row._oper),function(i){return _c('el-link',{key:i.name,staticClass:"links",attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.action(row, i)}}},[_vm._v(" "+_vm._s(i.name)+" ")])}):[_c('el-link',{staticClass:"links"},[_vm._v("/")])]]}}])})],1)],1),_c('receiptDrawDetail',{ref:"receiptDrawDetail"}),_c('receiptDraw',{ref:"receiptDraw"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }