<template>
  <div class="wrapper">
    <!-- <userInfoCom /> -->
    <div id="main_MyOrder_detail" style="width:1200px; margin:0 auto">
      <el-container style="height: 100%" class="head_info_bar">
        <el-header height="auto">
          <fw-header titles="详情" :headers="{ back: true }" />
        </el-header>
        <el-main v-if="dataInfo">
          <basmsg :dataInfo="dataInfo" :showStatus="true" />
          <div class="main_bot">
            <div>
              <span style="margin-right:45px;color:#858585;">配送至</span>
              <span style="margin-right:15px">
                {{ (dataInfo.receipt_info && dataInfo.receipt_info.name) || "--" }}
              </span>
              <span style="margin-right:15px">
                {{ dataInfo.receipt_info && dataInfo.receipt_info.mobile }}
              </span>
              <span style="margin-right:15px">
                {{ dataInfo.receipt_info && dataInfo.receipt_info.province_name }}&nbsp;{{
                  dataInfo.receipt_info && dataInfo.receipt_info.city_name
                }}&nbsp;{{ dataInfo.receipt_info && dataInfo.receipt_info.area_name }}&nbsp;{{
                  dataInfo.receipt_info && dataInfo.receipt_info.address
                }}
              </span>
              <i
                class="el-icon-edit empha"
                @click="choseAddress"
                v-if="dataInfo.order_status < 50"
              ></i>
            </div>
            <div>
              <el-button
                size="small"
                style="margin-right:20px;width: 88px"
                @click="action(dataInfo, i)"
                plain
                v-for="i in dataInfo._oper"
                :key="i.code"
              >
                {{ i.name }}
              </el-button>
            </div>
          </div>
          <div class="zone">
            <goods-list :tableList="dataInfo.order_com_sku" />
          </div>
          <div>
            <pay-info-table
              :tableList="dataInfo.order_pay"
              :totalPrice="Number(dataInfo.total_pay_amount)"
            />
          </div>
          <div>
            <shipments-info-table
              :tableList="dataInfo.order_shipping"
              :totalPrice="Number(dataInfo.total_invoice_amount)"
            />
          </div>
          <div>
            <invoice-info-table
              :tableList="invoiceArr"
              :totalPrice="Number(dataInfo.total_invoice_amount)"
            />
          </div>
          <div>
            <fw-timeline :array="arrayLog" />
          </div>
        </el-main>
      </el-container>
    </div>
    <!-- 取消订单 -->
    <cancelBox ref="cancelBox" />
    <!-- 发货弹窗 -->
    <shipmentDraw ref="shipmentDraw" />
    <!-- <fw-choseaddress ref="fwchoseaddress" /> -->

    <address-list-draw ref="addressListDraw" v-if="showAddress" @closeAddress="closeAddress" />
    <!-- 收货弹窗 -->
    <receiptDraw ref="receiptDraw" />
  </div>
</template>

<script>
import AddressListDraw from "@/views/myCaiGou/myShopping/components/addressListDraw.vue";
import cancelBox from "../components/cancelBox.vue";
import shipmentDraw from "../components/shipmentDraw.vue";
import Basmsg from "../components/basmsg.vue";
import GoodsList from "./components/goodsList.vue";
import PayInfoTable from "./components/payInfoTable.vue";
import ShipmentsInfoTable from "./components/shipmentsInfoTable.vue";
import InvoiceInfoTable from "./components/invoiceInfoTable.vue";
import receiptDraw from "../components/receiptDraw.vue";
import headerCode from "@/views/mySelf/components/header.vue";
import { mapGetters } from "vuex";
export default {
  name: "",
  components: {
    Basmsg,
    cancelBox,
    shipmentDraw,
    GoodsList,
    PayInfoTable,
    ShipmentsInfoTable,
    InvoiceInfoTable,
    receiptDraw,
    headerCode,
    AddressListDraw,
  },
  props: {},
  provide() {
    //注射给子组件方法
    return {
      getBasicInfo: this.getBasicInfo,
    };
  },
  created() {},
  mounted() {
    this.getBasicInfo();
  },
  data() {
    return {
      dataInfo: null,
      arrayLog: [],
      invoiceArr: [],
      showAddress: false, //地址列表弹窗
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["pageNav"]),
  },
  methods: {
    choseAddress() {
      this.showAddress = true;
      this.$nextTick(() => {
        this.$refs.addressListDraw.open({ addressInfo: this.dataInfo.receipt_info }, val => {
          console.log(val); // 返回所选择的参数
          if (val) {
            let receipt_info = {
              province_name: val.province,
              city_name: val.city,
              area_name: val.area,
              address: val.address,
              name: val.contacts_name ? val.contacts_name : this.dataInfo.receipt_info.name,
              mobile: val.contacts_mobile ? val.contacts_mobile : this.dataInfo.receipt_info.mobile,
            };
            let parmas = {
              id: Number(this.$route.query.id), //订单id
              receipt_info: receipt_info, //收货人信息 前端自定义
            };

            this.$axios
              .postJSON("/shop/public/common/personal/update_address", parmas)
              .then(res => {
                if (res.code == 200) {
                  console.log(res);
                  this.$message.success("操作成功!");
                  this.getBasicInfo();
                }
              });
          }
        });
      });
      // this.$refs.fwchoseaddress.open({ title: "选择配送地址", address_type: 1 }, val => {
      //   console.log(val);
      //   let receipt_info = {
      //     province_name: val.province,
      //     city_name: val.city,
      //     area_name: val.area,
      //     address: val.address,
      //     name: val.contacts_name ? val.contacts_name : this.dataInfo.receipt_info.name,
      //     mobile: val.contacts_mobile ? val.contacts_mobile : this.dataInfo.receipt_info.mobile,
      //   };
      //   let parmas = {
      //     id: Number(this.$route.query.id), //订单id
      //     receipt_info: receipt_info, //收货人信息 前端自定义
      //   };

      //   this.$axios.postJSON("/shop/public/common/personal/update_address", parmas).then(res => {
      //     if (res.code == 200) {
      //       console.log(res);
      //       this.$message.success("操作成功!");
      //       this.getBasicInfo();
      //     }
      //   });
      // });
    },
    closeAddress(value) {
      this.showAddress = value;
    },
    getBasicInfo() {
      this.$axios
        .get(`/shop/public/common/personal/detail`, { id: Number(this.$route.query.id) })
        .then(res => {
          if (res.data) {
            console.log(res.data);
            this.dataInfo = res.data;

            this.invoiceArr = res.data.invoice_list;
            this.getLog();
          }
        });
    },

    getLog() {
      this.$axios
        .get(`/shop/public/common/personal/log`, { order_id: Number(this.$route.query.id) })
        .then(res => {
          if (res.data) {
            let data = res.data.data;
            this.arrayLog = data;
          }
        });
    },
    action(row, i) {
      console.log(row, i);
      if (i.name == "收货") {
        //收货
        this.$refs.receiptDraw.open({ id: row.id }, val => {
          console.log(val);
          if (val) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.getBasicInfo();
          }
        });
      }
      if (i.name == "取消订单") {
        //取消订单
        this.$refs.cancelBox.open({}, val => {
          console.log(val);
          let params = {
            id: row.id, //订单id
            cancel_reason: val, //取消原因
          };
          this.$axios.putJSON("/shop/public/common/personal/cancel", params).then(res => {
            if (res.code == 200) {
              console.log(res);
              this.$message.success("操作成功");
              this.getBasicInfo();
            }
          });
        });
      }
      if (i.name == "收货详情") {
        //收货详情
        this.$refs.receiptDrawDetail.open({ id: row.id });
      }
      if (i.name == "线下支付") {
        //支付
        this.$router.push({
          path: "/myOrder/offlinePay",
          query: {
            id: row.id,
          },
        });
      }
      if (i.name == "复制订单") {
        //复制订单
        this.$confirm(
          `是否确认复制订单编号：<span style="color: #5589f8">${row.serial_number} </span>订单？`,
          "恢复提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            dangerouslyUseHTMLString: true, // 必须添加此选项，以允许在确认框中使用HTML字符串
          }
        )
          .then(() => {
            let api = `/shop/public/common/personal/copy`;
            this.$axios.postJSON(api, { id: row.id }).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.getBasicInfo();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
      if (i.name == "删除") {
        //删除
        this.$confirm(`是否确认删除订单编号：${row.serial_number}}订单？`, "删除提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let api = `/shop/public/common/personal/delete`;
            this.$axios.deltJSON(api, { id: [row.id] }).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.getBasicInfo();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
      if (i.name == "恢复") {
        //恢复
        this.$confirm(
          `是否确认恢复订单编号：<span style="color: #5589f8">${row.serial_number} </span>订单？`,
          "恢复提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            dangerouslyUseHTMLString: true, // 必须添加此选项，以允许在确认框中使用HTML字符串
          }
        )
          .then(() => {
            let api = `/shop/public/common/personal/restore`;
            this.$axios.postJSON(api, { id: [row.id] }).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.getBasicInfo();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
      if (i.name == "彻底删除") {
        //彻底删除
        this.$confirm(`一经删除后永不可恢复，是否确认删除？`, "彻底删除提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let api = `/shop/public/common/personal/complete`;
            this.$axios.deltJSON(api, { id: [row.id] }).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.getBasicInfo();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
      if (i.name == "线下开票") {
        //线下开票
        let ids = [row.id];
        let obj = {
          ids,
        };
        sessionStorage.setItem("invoicingIdsMyMy", JSON.stringify(obj));

        this.$router.push({
          path: "/myOrder/offlineInvoice",
        });
      }

      if (i.name == "签收" || i.name == "拒收") {
        //详情
        this.$router.push({
          path: "/myOrder/sign",
          query: {
            id: Number(row.id),
          },
        });
      }
      if (i.name == "线上支付") {
        /* 跳转 */
        let data = {
          token: sessionStorage.getItem("AccessToken"),
          domin: location.origin,
          order_id: row.id,
          order_type: "shop",
        };
        location.href = `https://pay.feiwin.cn/?data=${JSON.stringify(data)}`;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#main_MyOrder_detail {
  padding-bottom: 1px;
  box-sizing: border-box;
  height: 92vh;
  .zone {
    min-height: 10vh;
    height: auto;
  }
}
.main_bot {
  background-color: #fafafa;
  margin-top: 25px;
  line-height: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 25px;
  div {
    display: flex;
    align-items: center;
  }
}
.head_info_bar {
  background-color: #fff;
  padding: 0 15px;
  margin: 10px 0;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
</style>
