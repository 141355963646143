var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('fw-title',{attrs:{"open":_vm.open,"checks":true,"titles":"商品明细"},on:{"changes":function($event){_vm.open = !_vm.open}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}],staticStyle:{"height":"100%"}},[_c('vxe-table',{ref:"xTable1",staticClass:"mytable-scrollbar",staticStyle:{"min-height":"10vh"},attrs:{"stripe":"","highlight-hover-row":"","size":"small","id":"toolbar_demo3","data":_vm.tableList}},[_c('vxe-column',{attrs:{"type":"seq","title":"序号","width":"50","align":"center","field":"xuhao","fixed":"left"}}),_c('vxe-column',{attrs:{"min-width":"130","align":"center","fixed":"left"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("图片")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":row.com_sku.cover_img_path,"fit":"cover","preview-src-list":[row.com_sku.cover_img_path]}})]}}])}),_c('vxe-column',{attrs:{"min-width":"160","fixed":"left"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("商品信息")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.com_sku.name}},[_vm._v(" "+_vm._s(row.com_sku.name || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":("" + (row.com_sku.serial_number) + (row.com_sku.serial_number ? '-' + row.com_sku.goods_small_sort_num : ''))}},[_vm._v(" "+_vm._s(("" + (row.com_sku.serial_number) + (row.com_sku.serial_number ? "-" + row.com_sku.goods_small_sort_num : "")))+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.com_sku.info}},[_vm._v(" "+_vm._s(row.com_sku.info || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("商品规格")])]},proxy:true},{key:"default",fn:function(ref){
              var row = ref.row;
return [_c('p',{staticClass:"duohangEllipsisText",attrs:{"title":_vm.specJson(row.com_sku.spec_json)}},[_vm._v(" "+_vm._s(_vm.specJson(row.com_sku.spec_json) || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"80"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("单位")])]},proxy:true},{key:"default",fn:function(ref){
              var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.com_sku.unit_name}},[_vm._v(" "+_vm._s(row.com_sku.unit_name || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("平台SPU")]),_c('p',[_vm._v("平台SKU")])]},proxy:true},{key:"default",fn:function(ref){
              var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.com_sku.sku_number}},[_vm._v(" "+_vm._s(row.com_sku.sku_number || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.com_sku.spu_number}},[_vm._v(" "+_vm._s(row.com_sku.spu_number || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("销售单价")])]},proxy:true},{key:"default",fn:function(ref){
              var row = ref.row;
return [(row.protocol_sku_price != '0.00')?[_c('p',{staticClass:"ellipsisText",attrs:{"title":row.protocol_sku_price}},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(row.protocol_sku_price))+" ")])]:[_c('p',{staticClass:"ellipsisText",attrs:{"title":row.com_sku_price}},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(row.com_sku_price))+" ")])]]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("订购数量")])]},proxy:true},{key:"default",fn:function(ref){
              var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.buy_count}},[_vm._v(" "+_vm._s(row.buy_count || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("金额小计")])]},proxy:true},{key:"default",fn:function(ref){
              var row = ref.row;
return [_c('p',{staticStyle:{"color":"#f74848"}},[_vm._v(_vm._s(_vm._f("formatMoney")(row.com_sku_subtotal)))])]}}])})],1)],1),_c('fw-chosegoods',{ref:"fwchosegoods"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }