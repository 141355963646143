var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin":"10px 0"}},[_c('el-row',{staticStyle:{"align-items":"center"},attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":2}},[_c('fw-title',{staticStyle:{"padding-top":"20px"},attrs:{"open":_vm.open,"checks":true,"titles":"开票信息"},on:{"changes":function($event){_vm.open = !_vm.open}}})],1),_c('el-col',{attrs:{"span":22}},[(_vm.tableList.length > 0)?_c('div',[_c('span',{staticStyle:{"color":"#666"}},[_vm._v("合计开票金额 ：")]),_c('span',{staticStyle:{"color":"#f95c48","font-weight":"bold","font-size":"16px"}},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(_vm.totalPrice))+" ")])]):_c('div',[_vm._v(" ")])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}]},[_c('vxe-table',{ref:"xTable1",staticClass:"mytable-scrollbar",staticStyle:{"min-height":"10vh"},attrs:{"stripe":"","highlight-hover-row":"","size":"small","id":"toolbar_demo3","row-config":{ height: 120 },"data":_vm.tableList}},[_c('vxe-column',{attrs:{"fixed":"left","type":"seq","title":"序号","width":"50","align":"center","field":"xuhao"}}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("申请单号")]),_c('p',[_vm._v("开票方式")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText ",staticStyle:{"color":"#5894f8"},attrs:{"title":((row.apply_serial_number || '--') + " ")}},[_vm._v(" "+_vm._s(((row.apply_serial_number || "--") + " "))+" ")]),_c('p',{staticStyle:{"color":"#5894f8"}},[_vm._v(" "+_vm._s(((row.invoice_way == 1 ? "线上" : "线下") + " "))+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("申请状态")]),_c('p',[_vm._v("发票开票状态")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(row.apply_status == 0)?_c('div',{staticStyle:{"margin-right":"15px"}},[_c('span',{staticClass:"colors",staticStyle:{"background-color":"#e6a23c"}}),_c('span',[_vm._v("待开票")])]):(row.apply_status == 1)?_c('div',{staticStyle:{"margin-right":"15px"}},[_c('span',{staticClass:"colors",staticStyle:{"background-color":"#7ac756"}}),_c('span',[_vm._v("已开票")])]):(row.apply_status == 2)?_c('div',{staticStyle:{"margin-right":"15px"}},[_c('span',{staticClass:"colors",staticStyle:{"background-color":"#ccc"}}),_c('span',[_vm._v("已取消")])]):_c('div',[_vm._v(" -- ")]),(row.sign_status == 0)?_c('div',{staticStyle:{"margin-right":"15px"}},[_c('span',{staticClass:"colors",staticStyle:{"background-color":"#f8ba43"}}),_c('span',[_vm._v("未签收")])]):(row.sign_status == 1)?_c('div',{staticStyle:{"margin-right":"15px"}},[_c('span',{staticClass:"colors",staticStyle:{"background-color":"#7ac756"}}),_c('span',[_vm._v("已签收")])]):(row.sign_status == 2)?_c('div',{staticStyle:{"margin-right":"15px"}},[_c('span',{staticClass:"colors",staticStyle:{"background-color":"#f95c48"}}),_c('span',[_vm._v("已拒收")])]):_c('div',[_vm._v(" -- ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("收票人")]),_c('p',[_vm._v("寄票人")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.invoice_info && row.invoice_info.name}},[_vm._v(" "+_vm._s((row.invoice_info && row.invoice_info.name) || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.sender_info && row.sender_info.name}},[_vm._v(" "+_vm._s((row.sender_info && row.sender_info.name) || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("发票类型")]),_c('p',[_vm._v("本次申请开票金额")]),_c('p',[_vm._v("发票总金额")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.type}},[_vm._v(" "+_vm._s(row.type || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.invoice_amount}},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(row.invoice_amount))+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.type}},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(row.invoicing_amount))+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("关联发票")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.invoice_num}},[_vm._v(" "+_vm._s(row.invoice_num || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"230"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("申请人 | 时间")]),_c('p',[_vm._v("开票人 | 时间")]),_c('p',[_vm._v("取消人 | 时间")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText ",attrs:{"title":((row.apply_by || '--') + " | " + (row.apply_at || '--'))}},[_vm._v(" "+_vm._s(((row.apply_by || "--") + " | " + (row.apply_at || "--")))+" ")]),_c('p',{staticClass:"ellipsisText ",attrs:{"title":((row.invoiced_by || '--') + " | " + (row.invoiced_at || '--'))}},[_vm._v(" "+_vm._s(((row.invoiced_by || "--") + " | " + (row.invoiced_at || "--")))+" ")]),_c('p',{staticClass:"ellipsisText ",attrs:{"title":((row.cancel_by || '--') + " | " + (row.cancel_at || '--'))}},[_vm._v(" "+_vm._s(((row.cancel_by || "--") + " | " + (row.cancel_at || "--")))+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"230"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("签收人 | 时间")]),_c('p',[_vm._v("拒收人 | 时间")]),_c('p',[_vm._v("拒收意见")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText ",attrs:{"title":((row.signed_by || '--') + " | " + (row.signed_at || '--'))}},[_vm._v(" "+_vm._s(((row.signed_by || "--") + " | " + (row.signed_at || "--")))+" ")]),_c('p',{staticClass:"ellipsisText ",attrs:{"title":((row.rejected_by || '--') + " | " + (row.rejected_at || '--'))}},[_vm._v(" "+_vm._s(((row.rejected_by || "--") + " | " + (row.rejected_at || "--")))+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.signed_opinion}},[_vm._v(" "+_vm._s(row.signed_opinion || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"title":"操作","width":"100","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row._oper && row._oper.length > 0)?_vm._l((row._oper),function(i){return _c('el-link',{key:i.name,staticClass:"links",attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.action(row, i)}}},[_vm._v(" "+_vm._s(i.name)+" ")])}):[_c('el-link',{staticClass:"links"},[_vm._v("/")])]]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }