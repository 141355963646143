var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin":"10px 0","height":"100%"}},[_c('el-row',{staticStyle:{"align-items":"center"},attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":2}},[_c('fw-title',{staticStyle:{"padding-top":"20px"},attrs:{"open":_vm.open,"checks":true,"titles":"付款信息"},on:{"changes":function($event){_vm.open = !_vm.open}}})],1),_c('el-col',{attrs:{"span":22}},[(_vm.tableList.length > 0)?_c('div',[_c('span',{staticStyle:{"color":"#666"}},[_vm._v("合计付款金额 ：")]),_c('span',{staticStyle:{"color":"#f95c48","font-weight":"bold","font-size":"16px"}},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(_vm.totalPrice))+" ")])]):_c('div',[_vm._v(" ")])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}]},[_c('vxe-table',{ref:"xTable1",staticClass:"mytable-scrollbar",staticStyle:{"min-height":"10vh"},attrs:{"stripe":"","highlight-hover-row":"","size":"small","id":"toolbar_demo3","row-config":{ height: 120 },"data":_vm.tableList}},[_c('vxe-column',{attrs:{"fixed":"left","type":"seq","title":"序号","width":"50","align":"center","field":"xuhao"}}),_c('vxe-column',{attrs:{"min-width":"160","fixed":"left"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("付款单号 | 流水号")]),_c('p',[_vm._v("支付状态")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText ",attrs:{"title":((row.serial_number || '--') + " | " + (row.flow_serial_number || '--'))}},[_vm._v(" "+_vm._s(((row.serial_number || "--") + " | " + (row.flow_serial_number || "--")))+" ")]),(row.confirmed_status == 0)?[_c('span',{staticClass:"colors",staticStyle:{"background-color":"#f8ba43"}}),_c('span',{staticStyle:{"color":"#f8ba43"}},[_vm._v("待确认")])]:(row.confirmed_status == 2)?[_c('span',{staticClass:"colors",staticStyle:{"background-color":"#f74848"}}),_c('span',{staticStyle:{"color":"#f74848"}},[_vm._v("确认不通过")])]:(row.confirmed_status == 1)?[_c('span',{staticClass:"colors",staticStyle:{"background-color":"#7ac756"}}),_c('span',{staticStyle:{"color":"#7ac756"}},[_vm._v("确认通过")])]:[_vm._v(" -- ")]]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("付款金额")]),_c('p',[_vm._v("支付方式")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.pay_amount}},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(row.pay_amount))+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.settlement_method}},[_vm._v(" "+_vm._s(row.settlement_method || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("付款方名称")]),_c('p',[_vm._v("付款方编号")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.payer_name}},[_vm._v(" "+_vm._s(row.payer_name || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.pay_company_code}},[_vm._v(" "+_vm._s(row.pay_company_code || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("付款开户行")]),_c('p',[_vm._v("付款账号")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.pay_bank}},[_vm._v(" "+_vm._s(row.pay_bank || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.pay_number}},[_vm._v(" "+_vm._s(row.pay_number || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("收款方名称")]),_c('p',[_vm._v("收款方编号")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.payee_name}},[_vm._v(" "+_vm._s(row.payee_name || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.payee_company_code}},[_vm._v(" "+_vm._s(row.payee_company_code || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("收款开户行")]),_c('p',[_vm._v("收款账号")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.payee_bank}},[_vm._v(" "+_vm._s(row.payee_bank || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.payee_number}},[_vm._v(" "+_vm._s(row.payee_number || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("附件")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(row.annex && row.annex.length)?_vm._l((row.annex),function(item,index){return _c('p',{key:index,staticStyle:{"padding":"10px 0"}},[_c('fw-upload',{attrs:{"model":item,"view":true}})],1)}):_c('p',[_vm._v("--")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("付款人 | 付款时间")]),_c('p',[_vm._v("摘要")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText ",attrs:{"title":((row.payment_by || '--') + " | " + (row.payment_at || '--'))}},[_vm._v(" "+_vm._s(((row.payment_by || "--") + " | " + (row.payment_at || "--")))+" ")]),_c('p',[_vm._v("--")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("确认人 | 确认时间")]),_c('p',[_vm._v("备注")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText ",attrs:{"title":((row.confirmed_by || '--') + " | " + (row.confirmed_at || '--'))}},[_vm._v(" "+_vm._s(((row.confirmed_by || "--") + " | " + (row.confirmed_at || "--")))+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.confirmed_desc}},[_vm._v(" "+_vm._s(row.confirmed_desc || "--")+" ")])]}}])})],1)],1),_c('comfirmBox',{ref:"comfirmBox"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }