<template>
  <div style="margin:10px 0;height:100%">
    <el-row type="flex" style="align-items: center;">
      <el-col :span="2">
        <fw-title
          :open="open"
          :checks="true"
          titles="付款信息"
          @changes="open = !open"
          style="padding-top: 20px"
        />
      </el-col>
      <el-col :span="22">
        <div v-if="tableList.length > 0">
          <span style="color:#666;">合计付款金额 ：</span>
          <span style="color:#f95c48;font-weight:bold;font-size:16px">
            {{ totalPrice | formatMoney }}
          </span>
        </div>
        <div v-else>&nbsp;</div>
      </el-col>
    </el-row>
    <div v-show="open">
      <vxe-table
        style="min-height:10vh"
        class="mytable-scrollbar"
        stripe
        highlight-hover-row
        size="small"
        id="toolbar_demo3"
        :row-config="{ height: 120 }"
        ref="xTable1"
        :data="tableList"
      >
        <vxe-column
          fixed="left"
          type="seq"
          title="序号"
          width="50"
          align="center"
          field="xuhao"
        ></vxe-column>
        <vxe-column min-width="160" fixed="left">
          <template #header>
            <p>付款单号 | 流水号</p>
            <p>支付状态</p>
          </template>
          <template #default="{ row }">
            <p
              class="ellipsisText "
              :title="`${row.serial_number || '--'} | ${row.flow_serial_number || '--'}`"
            >
              {{ `${row.serial_number || "--"} | ${row.flow_serial_number || "--"}` }}
            </p>
            <template v-if="row.confirmed_status == 0">
              <span style="background-color: #f8ba43" class="colors"></span>
              <span style="color: #f8ba43">待确认</span>
            </template>
            <template v-else-if="row.confirmed_status == 2">
              <span style="background-color: #f74848" class="colors"></span>
              <span style="color: #f74848">确认不通过</span>
            </template>
            <template v-else-if="row.confirmed_status == 1">
              <span style="background-color: #7ac756" class="colors"></span>
              <span style="color: #7ac756">确认通过</span>
            </template>
            <template v-else>
              --
            </template>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>付款金额</p>
            <p>支付方式</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.pay_amount">
              {{ row.pay_amount | formatMoney }}
            </p>
            <p class="ellipsisText" :title="row.settlement_method">
              {{ row.settlement_method || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>付款方名称</p>
            <p>付款方编号</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.payer_name">
              {{ row.payer_name || "--" }}
            </p>
            <p class="ellipsisText" :title="row.pay_company_code">
              {{ row.pay_company_code || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>付款开户行</p>
            <p>付款账号</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.pay_bank">
              {{ row.pay_bank || "--" }}
            </p>
            <p class="ellipsisText" :title="row.pay_number">
              {{ row.pay_number || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>收款方名称</p>
            <p>收款方编号</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.payee_name">
              {{ row.payee_name || "--" }}
            </p>
            <p class="ellipsisText" :title="row.payee_company_code">
              {{ row.payee_company_code || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>收款开户行</p>
            <p>收款账号</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.payee_bank">
              {{ row.payee_bank || "--" }}
            </p>
            <p class="ellipsisText" :title="row.payee_number">
              {{ row.payee_number || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>附件</p>
          </template>
          <template #default="{ row }">
            <template v-if="row.annex && row.annex.length">
              <p style="padding: 10px 0" v-for="(item, index) in row.annex" :key="index">
                <fw-upload :model="item" :view="true" />
              </p>
            </template>
            <p v-else>--</p>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>付款人 | 付款时间</p>
            <p>摘要</p>
          </template>
          <template #default="{ row }">
            <p
              class="ellipsisText "
              :title="`${row.payment_by || '--'} | ${row.payment_at || '--'}`"
            >
              {{ `${row.payment_by || "--"} | ${row.payment_at || "--"}` }}
            </p>
            <p>--</p>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>确认人 | 确认时间</p>
            <p>备注</p>
          </template>
          <template #default="{ row }">
            <p
              class="ellipsisText "
              :title="`${row.confirmed_by || '--'} | ${row.confirmed_at || '--'}`"
            >
              {{ `${row.confirmed_by || "--"} | ${row.confirmed_at || "--"}` }}
            </p>
            <p class="ellipsisText" :title="row.confirmed_desc">
              {{ row.confirmed_desc || "--" }}
            </p>
          </template>
        </vxe-column>
        <!-- <vxe-column title="操作" width="100">
          <template #default="{row}">
            <template v-if="row._oper && row._oper.length > 0">
              <el-link
                @click="action(row, i)"
                class="links"
                :underline="false"
                type="primary"
                v-for="i in row._oper"
                :key="i.code"
              >
                {{ i.name }}
              </el-link>
            </template>
            <template v-else>
              <el-link class="links">/</el-link>
            </template>
          </template>
        </vxe-column> -->
      </vxe-table>
    </div>
    <comfirmBox ref="comfirmBox" />
  </div>
</template>

<script>
import comfirmBox from "./comfirmBox.vue";
export default {
  name: "",
  components: {
    comfirmBox,
  },
  props: {
    tableList: {
      type: Array,
      default: [],
    },
    totalPrice: {
      type: Number,
      default: 0,
    },
  },
  inject: ["getBasicInfo"],
  created() {},
  mounted() {},
  data() {
    return {
      open: true,
    };
  },
  watch: {},
  computed: {
    // totalPrice() {
    //   let sum = this.tableList.reduce(function(total, item) {
    //     return total + Number(item.pay_amount);
    //   }, 0);
    //   return sum;
    // },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.colors {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}
</style>
