<template>
  <div style="margin:10px 0">
    <el-row type="flex" style="align-items: center;">
      <el-col :span="2">
        <fw-title
          :open="open"
          :checks="true"
          titles="开票信息"
          @changes="open = !open"
          style="padding-top: 20px"
        />
      </el-col>
      <el-col :span="22">
        <div v-if="tableList.length > 0">
          <span style="color:#666;">合计开票金额 ：</span>
          <span style="color:#f95c48;font-weight:bold;font-size:16px">
            {{ totalPrice | formatMoney }}
          </span>
        </div>
        <div v-else>&nbsp;</div>
      </el-col>
    </el-row>
    <div v-show="open">
      <vxe-table
        style="min-height:10vh"
        class="mytable-scrollbar"
        stripe
        highlight-hover-row
        size="small"
        id="toolbar_demo3"
        :row-config="{ height: 120 }"
        ref="xTable1"
        :data="tableList"
      >
        <vxe-column
          fixed="left"
          type="seq"
          title="序号"
          width="50"
          align="center"
          field="xuhao"
        ></vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>申请单号</p>
            <p>开票方式</p>
          </template>
          <template #default="{ row }">
            <p
              class="ellipsisText "
              style="color:#5894f8"
              :title="`${row.apply_serial_number || '--'} `"
            >
              {{ `${row.apply_serial_number || "--"} ` }}
            </p>
            <p style="color:#5894f8">
              {{ `${row.invoice_way == 1 ? "线上" : "线下"} ` }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>申请状态</p>
            <p>发票开票状态</p>
          </template>
          <template #default="{ row }">
            <div v-if="row.apply_status == 0" style="margin-right:15px">
              <span style="background-color: #e6a23c" class="colors"></span>
              <span>待开票</span>
            </div>
            <div v-else-if="row.apply_status == 1" style="margin-right:15px">
              <span style="background-color: #7ac756" class="colors"></span>
              <span>已开票</span>
            </div>
            <div v-else-if="row.apply_status == 2" style="margin-right:15px">
              <span style="background-color: #ccc" class="colors"></span>
              <span>已取消</span>
            </div>
            <div v-else>
              --
            </div>
            <div v-if="row.sign_status == 0" style="margin-right:15px">
              <span style="background-color: #f8ba43" class="colors"></span>
              <span>未签收</span>
            </div>
            <div v-else-if="row.sign_status == 1" style="margin-right:15px">
              <span style="background-color: #7ac756" class="colors"></span>
              <span>已签收</span>
            </div>
            <div v-else-if="row.sign_status == 2" style="margin-right:15px">
              <span style="background-color: #f95c48" class="colors"></span>
              <span>已拒收</span>
            </div>
            <div v-else>
              --
            </div>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>收票人</p>
            <p>寄票人</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.invoice_info && row.invoice_info.name">
              {{ (row.invoice_info && row.invoice_info.name) || "--" }}
            </p>
            <p class="ellipsisText" :title="row.sender_info && row.sender_info.name">
              {{ (row.sender_info && row.sender_info.name) || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>发票类型</p>
            <p>本次申请开票金额</p>
            <p>发票总金额</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.type">
              {{ row.type || "--" }}
            </p>
            <p class="ellipsisText" :title="row.invoice_amount">
              {{ row.invoice_amount | formatMoney }}
            </p>
            <p class="ellipsisText" :title="row.type">
              {{ row.invoicing_amount | formatMoney }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>关联发票</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.invoice_num">
              {{ row.invoice_num || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="230">
          <template #header>
            <p>申请人 | 时间</p>
            <p>开票人 | 时间</p>
            <p>取消人 | 时间</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText " :title="`${row.apply_by || '--'} | ${row.apply_at || '--'}`">
              {{ `${row.apply_by || "--"} | ${row.apply_at || "--"}` }}
            </p>
            <p
              class="ellipsisText "
              :title="`${row.invoiced_by || '--'} | ${row.invoiced_at || '--'}`"
            >
              {{ `${row.invoiced_by || "--"} | ${row.invoiced_at || "--"}` }}
            </p>
            <p class="ellipsisText " :title="`${row.cancel_by || '--'} | ${row.cancel_at || '--'}`">
              {{ `${row.cancel_by || "--"} | ${row.cancel_at || "--"}` }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="230">
          <template #header>
            <p>签收人 | 时间</p>
            <p>拒收人 | 时间</p>
            <p>拒收意见</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText " :title="`${row.signed_by || '--'} | ${row.signed_at || '--'}`">
              {{ `${row.signed_by || "--"} | ${row.signed_at || "--"}` }}
            </p>
            <p
              class="ellipsisText "
              :title="`${row.rejected_by || '--'} | ${row.rejected_at || '--'}`"
            >
              {{ `${row.rejected_by || "--"} | ${row.rejected_at || "--"}` }}
            </p>
            <p class="ellipsisText" :title="row.signed_opinion">
              {{ row.signed_opinion || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column title="操作" width="100" fixed="right">
          <template #default="{row}">
            <template v-if="row._oper && row._oper.length > 0">
              <el-link
                @click="action(row, i)"
                class="links"
                :underline="false"
                type="primary"
                v-for="i in row._oper"
                :key="i.name"
              >
                {{ i.name }}
              </el-link>
            </template>
            <template v-else>
              <el-link class="links">/</el-link>
            </template>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    tableList: {
      type: Array,
      default: [],
    },
    totalPrice: {
      type: Number,
      default: 0,
    },
  },
  inject: ["getBasicInfo"],
  created() {},
  mounted() {},
  data() {
    return {
      open: true,
    };
  },
  watch: {},
  computed: {
    // totalPrice() {
    //   let sum = this.tableList.reduce(function(total, item) {
    //     if (item.apply_status !== 2 && item.sign_status !== 2) {
    //       total += item.invoice_amount;
    //     }
    //     return total;
    //   }, 0);
    //   return sum;
    // },
  },
  methods: {
    action(row, i) {
      if (i.name == "详情") {
        //详情
        this.$router.push({
          path: "/myOrder/sign",
          query: {
            id: Number(row.id),
          },
        });
      }
      if (i.name == "签收" || i.name == "拒收") {
        //详情
        this.$router.push({
          path: "/myOrder/sign",
          query: {
            id: Number(row.id),
          },
        });
      }
      if (i.name == "编辑") {
        //详情
        let ids = [Number(this.$route.query.id)];
        let obj = {
          ids,
        };
        sessionStorage.setItem("invoicingIdsMyMy", JSON.stringify(obj));

        this.$router.push({
          path: "/myOrder/offlineInvoice",
          query: { order_invoice_id: row.id },
        });
      }
      if (i.name == "取消开票") {
        //取消开票
        let params = {
          order_invoice_id: row.id,
        };
        let api = `/shop/order/buyer/cancel_apply`;
        this.$axios.putJSON(api, params).then(res => {
          if (res.code == 200) {
            this.$message.success("操作成功");
            this.getBasicInfo();
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.links {
  margin: 0 10px;
}
.colors {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}
</style>
