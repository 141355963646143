<template>
  <div style="margin:10px 0">
    <el-row type="flex" style="align-items: center;">
      <el-col :span="2">
        <fw-title
          :open="open"
          :checks="true"
          titles="发货信息"
          @changes="open = !open"
          style="padding-top: 20px"
        />
      </el-col>
      <el-col :span="22">
        <div v-if="tableList.length > 0">
          <span style="color:#666;">合计发货金额 ：</span>
          <span style="color:#f95c48;font-weight:bold;font-size:16px">
            {{ totalPrice | formatMoney }}
          </span>
        </div>
        <div v-else>&nbsp;</div>
      </el-col>
    </el-row>
    <div v-show="open">
      <vxe-table
        style="min-height:10vh"
        class="mytable-scrollbar"
        stripe
        highlight-hover-row
        size="small"
        id="toolbar_demo3"
        :row-config="{ height: 120 }"
        ref="xTable1"
        :data="tableList"
      >
        <vxe-column
          fixed="left"
          type="seq"
          title="序号"
          width="50"
          align="center"
          field="xuhao"
        ></vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>发货单号</p>
            <p>发货方名称</p>
            <p>收货状态</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText " :title="`${row.serial_number || '--'} `">
              {{ `${row.serial_number || "--"} ` }}
            </p>
            <p class="ellipsisText " :title="`${row.send_name || '--'} `">
              {{ `${row.send_name || "--"} ` }}
            </p>
            <template v-if="row.receipt_status == 1">
              <span style="background-color: #f8ba43" class="colors"></span>
              <span style="color: #f8ba43">待收货</span>
            </template>
            <template v-else-if="row.receipt_status == 2">
              <span style="background-color: #7ac756" class="colors"></span>
              <span style="color: #7ac756">已收货</span>
            </template>
            <template v-else>
              --
            </template>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>发货金额</p>
            <p>收货金额</p>
          </template>
          <template #default="{ row }">
            <p
              class="ellipsisText"
              :title="row.shipping_amount"
              style="color:#f95c48;font-weight:bold;"
            >
              {{ row.shipping_amount | formatMoney }}
            </p>
            <p
              class="ellipsisText"
              :title="row.receive_amount"
              style="color:#f95c48;font-weight:bold;"
            >
              {{ row.receive_amount | formatMoney }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>收货人</p>
            <p>联系方式</p>
            <p>收货地址</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.receive_by">
              {{ row.receive_by || "--" }}
            </p>
            <p class="ellipsisText" :title="row.receive_mobile">
              {{ row.receive_mobile || "--" }}
            </p>
            <p class="ellipsisText" :title="row.receive_address">
              {{ row.receive_address || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>物流单号</p>
            <p>物流到货时间</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.logistics_serial_number">
              {{ row.logistics_serial_number || "--" }}
            </p>
            <template v-if="row.arrival_at == '0000-00-00 00:00:00'">
              --
            </template>
            <template v-else>
              <p class="ellipsisText" :title="row.arrival_at">
                {{ row.arrival_at || "--" }}
              </p>
            </template>
          </template>
        </vxe-column>
        <vxe-column min-width="230">
          <template #header>
            <p>发货提交人 | 提交时间</p>
            <p>收货提交人 | 提交时间</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText " :title="`${row.send_by || '--'} | ${row.send_at || '--'}`">
              {{ `${row.send_by || "--"} | ${row.send_at || "--"}` }}
            </p>
            <p
              class="ellipsisText "
              :title="`${row.receive_by || '--'} | ${row.receive_at || '--'}`"
            >
              {{ `${row.receive_by || "--"} | ${row.receive_at || "--"}` }}
            </p>
          </template>
        </vxe-column>
        <vxe-column title="操作" width="100">
          <template #default="{row}">
            <template v-if="row._oper && row._oper.length > 0">
              <el-link
                @click="action(row, i)"
                class="links"
                :underline="false"
                type="primary"
                v-for="i in row._oper"
                :key="i.name"
              >
                {{ i.name }}
              </el-link>
            </template>
            <template v-else>
              <el-link class="links">/</el-link>
            </template>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <!-- 发货详情 -->
    <!-- <shipments-detail-draw ref="shipmentsDetailDraw" /> -->
    <!-- 收货弹窗详情 -->
    <receiptDrawDetail ref="receiptDrawDetail" />
    <!-- 收货弹窗 -->
    <receiptDraw ref="receiptDraw" />
  </div>
</template>

<script>
import receiptDrawDetail from "@/views/myCaiGou/myOrder/components/receiptDrawDetail.vue";
import receiptDraw from "../../components/receiptDraw.vue";
export default {
  name: "",
  components: { receiptDrawDetail, receiptDraw },
  props: {
    tableList: {
      type: Array,
      default: [],
    },
  },
  inject: ["getBasicInfo"],
  created() {},
  mounted() {},
  data() {
    return {
      open: true,
    };
  },
  watch: {},
  computed: {
    totalPrice() {
      let sum = this.tableList.reduce(function(total, item) {
        return total + Number(item.shipping_amount);
      }, 0);
      return sum;
    },
  },
  methods: {
    action(row, i) {
      if (i.name == "查看详情") {
        this.$refs.receiptDrawDetail.open({ id: Number(this.$route.query.id), rowId: row.id });
      }
      if (i.name == "物流详情") {
        console.log(row);
      }
      if (i.name == "收货") {
        this.$refs.receiptDraw.open({ id: Number(this.$route.query.id) }, val => {
          console.log(val);
          if (val) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.getBasicInfo();
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.colors {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}
</style>
